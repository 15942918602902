import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import md from "md.js";
import JustifiedLayout from "@codekraft-studio/react-justified-layout";

import { H1 } from "../components/Typography";
import Underline from "../components/Underline";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import missionIcon from "../../static/images/mission.svg";
import visionIcon from "../../static/images/vision.svg";
import deskImage from "../../static/images/desk.svg";
import useSiteMetadata from "../components/SiteMetadata";

export const AboutPageTemplate = ({ content }) => {
  const { siteUrl } = useSiteMetadata();
  return (
    <>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: `${siteUrl}/about`,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest"
        }}
      />
      <section className="about">
        <div className="container">
          <H1 alignCenter>About Us</H1>
          <Underline />
          <h2>{content.hero.title}</h2>
          <div className="hero-content">
            <p className="left">{content.hero.leftPara}</p>
            <div className="image-container">
              <PreviewCompatibleImage
                imageInfo={content.hero.image}
                alt="about us"
              />
            </div>
            <p className="right">{content.hero.rightPara}</p>
          </div>
        </div>
      </section>
      <section className="bg story">
        <div className="container">
          <div className="goals">
            <div className="goal">
              <div className="image-container">
                <img src={missionIcon} alt="mission icon" />
              </div>
              <h2>{content.mission.title}</h2>
              <p>{content.mission.description}</p>
            </div>
            <div className="goal">
              <div className="image-container">
                <img src={visionIcon} alt="vision icon" />
              </div>
              <h2>{content.vision.title}</h2>
              <p>{content.vision.description}</p>
            </div>
          </div>
          <H1 alignCenter as="h2">
            {content.story.title}
          </H1>
          <Underline />
          <div className="story-content">
            <img src={deskImage} alt="desk" />
            <HTMLContent
              className="markdown"
              content={md(content.story.description)}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <H1 alignCenter as="h2">
            Our Approach
          </H1>
          <Underline />
          <ul className="approaches">
            {content.approach.map((approach, index) => (
              <li key={index}>
                <div className="content">
                  <h3>{approach.title}</h3>
                  <p>{approach.description}</p>
                </div>
                <div className="image">
                  <PreviewCompatibleImage
                    imageInfo={approach.image}
                    alt={approach.title}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="farm">
        <H1 alignCenter as="h2">
          Our Farm
        </H1>
        <Underline />
        <JustifiedLayout
          items={content.farmImages.map(
            image => image.childImageSharp.fluid.aspectRatio
          )}
          options={{ targetRowHeight: 200 }}
        >
          {items =>
            items.map((item, index) => {
              return (
                <div key={index} style={item.style}>
                  <PreviewCompatibleImage
                    imageInfo={content.farmImages[index]}
                    alt="farm image"
                  />
                </div>
              );
            })
          }
        </JustifiedLayout>
      </section>
      <style jsx>{`
        section {
          padding: 32px;
        }

        section.about,
        section.farm {
          padding-bottom: 80px;
        }

        section.story {
          padding-top: 64px;
        }

        section.bg {
          background-image: linear-gradient(180deg, #f5f0eb, #fff);
        }

        h2,
        h3 {
          font-family: "Open Sans", sans-serif;
        }

        p {
          font-family: "Lato", sans-serif;
          font-weight: 300;
          line-height: 1.4;
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
        }

        h2 {
          font-size: 22px;
          text-align: center;
        }

        .hero-content {
          width: 100%;
          font-family: "Lato", sans-serif;
          display: flex;
          align-items: center;
        }

        .hero-content p {
          flex: 1;
          order: 1;
          font-size: 20px;
        }

        .hero-content .left {
          text-align: left;
        }

        .hero-content .right {
          text-align: right;
        }

        .hero-content .image-container {
          flex: 1;
          order: 1;
        }

        .goals {
          margin: 0 auto;
          max-width: 1024px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
        }

        .goal .image-container {
          text-align: center;
        }

        .goal p {
          max-width: 340px;
          text-align: center;
          font-size: 20px;
          margin-left: auto;
          margin-right: auto;
        }

        .story-content :global(.markdown) :global(p) {
          font-size: 22px;
          line-height: 1.5;
          font-weight: 300;
        }

        .story-content img {
          float: right;
        }

        .approaches {
          max-width: 800px;
          list-style: none;
          margin: 0 auto;
          padding: 0;
        }

        .approaches li {
          display: flex;
          align-items: center;
          margin-bottom: 48px;
        }

        .approaches li .content,
        .approaches li .image {
          flex: 1;
          order: 1;
        }

        .approaches li .image :global(.gatsby-image-wrapper) {
          width: 100%;
        }

        .approaches li .content {
          margin-right: 48px;
        }

        .approaches li p {
          margin: 0;
          font-size: 20px;
        }

        .approaches li:nth-child(even) .content {
          order: 2;
          margin-right: 0;
          text-align: right;
        }

        .approaches li:nth-child(even) .image {
          margin-right: 48px;
        }

        @media (max-width: 1180px) {
          .hero-content p {
            font-size: 16px;
          }
        }

        @media (max-width: 980px) {
          .hero-content {
            flex-direction: column;
          }
          .hero-content p {
            order: 2;
            font-size: 20px;
            margin: 0;
            margin-bottom: 16px;
          }
          .hero-content .right {
            text-align: left;
          }

          .hero-content .image-container {
            width: 300px;
            margin-bottom: 16px;
          }
        }

        @media (max-width: 940px) {
          .story-content img {
            float: unset;
            display: block;
            margin: 0 auto;
          }
        }

        @media (max-width: 800px) {
          .approaches {
            max-width: 600px;
          }
          .approaches li {
            flex-direction: column-reverse;
            justify-content: center;
            text-align: center;
          }

          .approaches li .image {
            width: 100%;
            max-width: 300px;
          }

          .approaches li:nth-child(even) .content {
            order: 1;
            text-align: center;
          }

          .approaches li .content,
          .approaches li:nth-child(even) .image {
            margin-right: 0;
          }
        }

        @media (max-width: 780px) {
          .goals {
            flex-direction: column;
            justify-content: flex-start;
          }

          .goal {
            margin-bottom: 32px;
          }

          .goal p {
            max-width: 480px;
          }
        }

        @media (max-width: 640px) {
          p,
          .hero-content p,
          .goal p,
          .story-content :global(.markdown) :global(p),
          .approaches li p {
            font-size: 18px;
          }

          .story-content img {
            width: 100%;
            max-width: 540px;
          }

          section.about {
            padding-bottom: 32px;
          }

          section.farm {
            padding-top: 0;
          }
        }

        @media (max-width: 480px) {
          section {
            padding: 32px 20px;
          }

          .approaches li .image {
            width: 100%;
            max-width: unset;
          }
        }
      `}</style>
    </>
  );
};

AboutPageTemplate.propTypes = {
  content: PropTypes.object
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout whiteHeader isAboutPage>
      <AboutPageTemplate content={post.frontmatter} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
        hero {
          title
          leftPara
          rightPara
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        mission {
          title
          description
        }
        vision {
          title
          description
        }
        story {
          title
          description
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        approach {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
        farmImages {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
